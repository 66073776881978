<template>
  <div class="buyWatch" id="buyWatchId">
    <section>
      <v-layout justify-center class="mt-5">
        <v-row>
          <h1 class="display-1 iceBlue mx-12 ">{{content.title}}</h1>
        </v-row>
      </v-layout>
    </section>
    <section>
      <div class="text-center">
        <v-dialog v-model="showDialog" width="80%">
          <!-- <template v-slot:activator="{ on }">
            <v-btn id="watchDetailDialog" color="red lighten-2" dark v-on="on">Details</v-btn>
          </template>-->

          <!-- <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Privacy Policy
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showDialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
          </v-card>-->
          <v-card class="fullwidth d-inline-block mx-auto whiteBackgroup">
            <v-container>
              <v-row class="rightContent">
                <v-btn id="closeBtn" class="bth30width ma-1 pa-1" text @click="showDialog = false">x</v-btn>
              </v-row>
              <v-row class>
                <v-col cols="12">
                  <v-row class="font-weight-black display-1 pl-6 showEnter">{{pName}}</v-row>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                      lg="2"
                      justify="flex-start"
                      v-show="pPictures.length > 1"
                    >
                      <div
                        cols="3"
                        class="ma-0 pa-0"
                        align="center"
                        v-for="(watchPicture, i) in pPictures"
                        :key="i"
                      >
                        <v-img
                          @click="pPicture = watchPicture"
                          max-height="50"
                          max-width="50"
                          aspect-ratio="1"
                          :src="watchPicture"
                          alt="watch detils"
                        ></v-img>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      v-bind:class="['{{pPictures.length > 1}}' ? 'md-10 lg-10' : 'md-12 lg-12']"
                    >
                      <!-- <img class="inheritWidth" :src="pPicture" /> -->
                      <!-- <v-img class="inheritWidth"  :src="pPicture" /> -->
                      <!-- <ZoomOnHover :img-normal="pPicture"></ZoomOnHover> -->
                      <!-- <div style="width:400px"> -->
                      <div>
                        <!-- <zoom-on-hover :img-normal="pPicture" :scale="0.8"></zoom-on-hover> -->
                        <ZoomOnHover :img-normal="pPicture"></ZoomOnHover>
                      </div>
                    </v-col>
                    <v-col>
                      <p class="subtitle text-right grey--text" >{{content.zoomtext}}</p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col xs="12" md="6" class="text-left title pa-4">
                  <v-row class="pa-3">
                    <!-- <v-col cols="2"> -->
                      <!-- <v-btn icon> -->
                      <!-- <v-icon color="black">mdi-comment-text</v-icon> -->
                      <!-- </v-btn> -->
                    <!-- </v-col> -->
                    <!-- <v-col cols="10"> -->
                      <p class="showEnter">{{pDescription}}</p>
                    <!-- </v-col> -->
                  </v-row>
                  <!-- <v-row class="pa-3">
                    <v-col cols="2">
                      <v-btn icon>
                        <v-icon color="black">mdi-map-marker</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="10">Mong Kok</v-col>
                  </v-row>-->
                  <v-row class="pa-3">
                    <!-- <v-col cols="2"> -->
                      <!-- <v-btn icon> -->
                      <!-- <v-icon color="black">mdi-cash-usd-outline</v-icon> -->
                      <!-- </v-btn> -->
                    <!-- </v-col> -->
                    <!-- <v-col cols="10"> -->
                      {{content.price}}{{priceFormat(pPrice)}}
                      <!-- </v-col> -->
                  </v-row>
                  <!-- <v-row class="pa-3">
                    <v-col cols="2">
                      <v-btn icon>
                        <v-icon color="black">mdi-watermark</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="10">
                      <p>{{pBrand}}</p>
                    </v-col>
                  </v-row>-->
                  <v-row class="pa-3">
                    <!-- <v-col cols="2"> -->
                      <!-- <v-btn icon> -->
                      <!-- <v-icon class="pt-5" color="black">mdi-share-variant</v-icon> -->
                      <!-- </v-btn> -->
                    <!-- </v-col> -->
                    <!-- <v-col cols="10"> -->
                      <!-- <v-btn icon>
                        <v-icon>fab fa-facebook</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon>fab fa-whatsapp</v-icon>
                      </v-btn>-->
                      <!-- <v-card-actions class="white"> -->
                      <v-btn
                        v-for="(social, i) in socials"
                        :key="i"
                        :color="social.color"
                        class="red--text"
                        :href="social.icon==='fab fa-whatsapp' ? social.link + encodeURI( pName ) : (social.icon==='fab fa-instagram' && pIgLink ? pIgLink : social.link) "
                        fab
                        icon
                        large
                      >
                        <!-- <v-icon x-large>{{ social.icon }}</v-icon> -->
                        <v-img
                            class="inheritColor"
                            v-if="social.extraImage"
                            aspect-ratio="1"
                            width="40"
                            height="40"
                            :src="social.extraImage"
                          ></v-img>
                          <v-icon v-else>{{ social.icon }}</v-icon>
                      </v-btn>
                      <!-- </v-card-actions> -->
                    <!-- </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </section>

    <WatchCard />
  </div>
</template>

<script>
//   new Vue({
//    el: '#app',
//    vuetify: new Vuetify(),
//    data () {
//      return {
//        title: 'Your Logo'
//      }
//    }
//  })

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import WatchCard from '@/components/WatchCard.vue'
import ZoomOnHover from '@/components/ZoomOnHover.vue'

export default {
  name: 'buyWatch',
  data () {
    return {
      content: {},
      // title: '選購名錶',
      pPicture: '',
      pPictures: [],
      pName: '',
      pDescription: '',
      pPrice: '',
      pBrand: '',
      pState: '',
      pIgLink: '',
      showDialog: false,
      // content: '',
      socials: [
        {
          icon: 'fab fa-whatsapp',
          color: 'green lighten-3',
          link:
            'https://wa.me/85268819431/?text=' +
            encodeURI('我想揾一隻適合我嘅手錶!')
        },
        {
          icon: 'fas fa-camera-retro',
          color: 'orange lighten-2',
          extraImage:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2FcarousellIcon2.png?alt=media&token=26e50ca6-557b-4360-8afd-7c3b081347a1',

          link: 'https://hk.carousell.com/wewatch/'
        },
        {
          icon: 'fab fa-instagram',
          color: 'red lighten-3',
          link: 'https://www.instagram.com/wewatchhk/'
        },
        {
          icon: 'fab fa-facebook',
          color: 'blue',
          link: 'https://www.facebook.com/wewatchhk/'
        }
      ]
    }
  },
  components: {
    // HelloWorld,
    // Footer,
    WatchCard,
    ZoomOnHover
  },
  beforeCreate () {
    // localStorage.currentPage = 'home'
    localStorage.currentPage = 'buyWatch'
  },
  mounted () {
    if (localStorage.language !== 'en') {
      this.content.title = '選購名錶'
      this.content.zoomtext = '點擊相片可放大細節'
    } else {
      this.content.title = 'Buy Watches'
      this.content.zoomtext = 'Click the image to zoom out the details'
    }
  },
  created () {
    if (localStorage.pPicture) {
      this.pPicture = localStorage.pPicture
    }
    // if (localStorage.pPictures) {
    //   console.log('localStorage.pPictures')
    //   console.log(localStorage.pPictures)
    //   this.pPictures = localStorage.pPictures
    //   console.log('this.pPictures')
    //   console.log(this.pPictures)
    // }
    if (localStorage.pName) {
      this.pName = localStorage.pName
      localStorage.pName = ''
    }
    if (localStorage.pDescription) {
      this.pDescription = localStorage.pDescription
      localStorage.pDescription = ''
    }
    if (localStorage.pPrice) {
      this.pPrice = localStorage.pPrice
      localStorage.pPrice = ''
    }
    if (localStorage.pBrand) {
      this.pBrand = localStorage.pBrand
      localStorage.pBrand = ''
    }
    if (localStorage.pState) {
      this.pState = localStorage.pState
      localStorage.pState = ''
    }
    if (localStorage.showDialog && String(localStorage.showDialog) === 'true') {
      // console.log('this.showDialog: ' + this.showDialog)
      // console.log(this.showDialog ? '1.true' : '2.false')
      this.showDialog = true
    } else {
      this.showDialog = false
    }
    localStorage.showDialog = ''

    if (localStorage.pIgLink) {
      this.pIgLink = localStorage.pIgLink
      localStorage.pIgLink = ''
    }

    //   //   WatchCard.fetchData()
    //   //   console.log('allwatches')
    //   //   console.log(this.allwatches)
    //   // document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    if (localStorage.language !== 'en') {
      this.content.price = '現金價：$'
    } else {
      this.content.price = 'HK$'
    }
  },
  methods: {
    priceFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
<style scoped>
.whiteBackgroup {
  background-color: white;
}

button.bth30width {
  width: 30px;
  height: 30px;
}
#closeBtn {
  width: 30px;
  height: 30px;
  min-width: 30px;
}
.whiteBackgroup.v-sheet {
  /* background-color: #424242;
    border-color: #424242; */
  color: rgb(0, 0, 0);
}
.inheritWidth {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.rightContent {
  justify-content: flex-end;
}
</style>
